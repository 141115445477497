import { FC, MouseEvent, SVGProps } from 'react';
import classes from './iconButton.module.scss';
import cx from 'classnames';

export type IconButtonProps = {
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (e: MouseEvent<HTMLButtonElement>) => void;
  onBlur?: () => void;
  disabled?: boolean;
  Icon?: FC<SVGProps<any>>;
  extraIcon?: FC<SVGProps<any>> | JSX.Element | null;
  className?: string;
  bordered?: boolean;
  right?: boolean;
  iconOnly?: boolean;
  id?: string;
};

export const IconButton: FC<IconButtonProps> = ({
  children,
  Icon,
  right,
  bordered,
  iconOnly,
  extraIcon,
  className,
  ...props
}) => {
  return (
    <button
      type="button"
      className={cx(
        classes.btn,
        {
          [classes.iconOnly]: !children || iconOnly,
          [classes.bordered]: bordered,
          [classes.right]: right,
        },
        className
      )}
      {...props}
    >
      {!!Icon && (
        <div className={classes.iconWrapper}>
          <Icon className={classes.icon} />
          {extraIcon}
        </div>
      )}
      {!!Icon && <div className={classes.spacer} />}
      {children ? <div className={classes.label}>{children}</div> : children}
    </button>
  );
};
