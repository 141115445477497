import { createContext, FC, useCallback, useContext, useState } from 'react';
import classes from './loader.module.scss';
import { Loader } from 'components/Loader';

const LoaderContext = createContext({} as { showLoader: () => void; hideLoader: () => void });
export const useLoader = () => useContext(LoaderContext);

export const LoaderProvider: FC = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const showLoader = useCallback(() => setLoading(true), []);
  const hideLoader = useCallback(() => setLoading(false), []);

  return (
    <LoaderContext.Provider value={{ showLoader, hideLoader }}>
      {children}
      {loading && (
        <div className={classes.loader}>
          <Loader />
        </div>
      )}
    </LoaderContext.Provider>
  );
};
