import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { PortalFilter, TEntityName } from 'lib';
import { Sort } from 'components/Table/hooks';
import { useHistory } from 'react-router-dom';
import { ColumnConfig, View } from 'components/ListPage/hook';
import { TListPage } from 'components/ListPage';
import config, { systemBasedFields } from 'config';
import { useMetaData } from 'lib/hooks';
import { UserSettingsContext } from 'providers/UserSettingsProvider';
import { useViews } from 'providers/TableProvider/hooks';
import { TLinkEntity, EntityLink } from 'components/ListPage/index';
import { devLog } from 'lib/helpers';

export type QueryParams = {
  page: number;
  sorting: Sort[];
  filters: PortalFilter[];
  search?: string;
  links?: TLinkEntity;
};

export type ListProps = {
  queryParams: QueryParams;
  columnsConfig: ColumnConfig[];
  currentView: string;
  updateQueryParams: (props: Partial<QueryParams>) => void;
  setColumnsConfig: Dispatch<SetStateAction<ColumnConfig[]>>;
  setCurrentView: (id: string) => void;
  columns: string[];
  views: Record<string, View>;
  setViews: Dispatch<SetStateAction<Record<string, View>>>;
  logicalName: string;
};

type ViewProps = {
  hasChanges: boolean;
  isCustomView: boolean;
};

export const TableContext = createContext({} as ListProps & ViewProps);

export function deepEqual(first: any, second: any, ignoredKeys: string[] = []): boolean {
  if (typeof first !== typeof second) return false;
  if (Array.isArray(first) && Array.isArray(second)) {
    if (first.length !== second.length) return false;
    return first.every((value, index) => deepEqual(value, second[index]));
  }
  if (typeof first === 'object' && typeof second === 'object' && first !== null && second !== null) {
    if (Object.keys(first).length !== Object.keys(second).length) return false;
    return Object.keys(first)
      .filter((key) => !ignoredKeys.includes(key))
      .every((key) => deepEqual(first[key], second[key]));
  }
  return first === second;
}

const unifyPathName = (pathname: string) =>
  pathname
    .split('/')
    .filter((v) => !!v)
    .filter((_, index) => index !== 1)
    .join('/');

export const MIN_CELL_WIDTH = 160;

export const TableProvider: FC<{
  initialValues?: Partial<QueryParams>;
  entityName: TEntityName;
  dialog?: string;
  columns: readonly string[];
  systemView?: string;
  systemFields?: boolean;
  hasViews?: boolean;
  baseColumnsConfig?: ColumnConfig[];
}> = ({
  initialValues = {},
  entityName,
  columns: baseColumnsList = [],
  dialog = '',
  systemView,
  hasViews = true,
  systemFields = true,
  children,
  baseColumnsConfig,
}) => {
  const { getFieldDefinition, logicalName, hiddenFields } = useMetaData(entityName);

  const columns = useMemo(
    () =>
      [...(systemFields ? (systemBasedFields as unknown as string[]) : []), ...baseColumnsList].filter(
        (v, index, arr) => arr.indexOf(v) === index && getFieldDefinition(v) && !hiddenFields.includes(v)
      ),
    [baseColumnsList, getFieldDefinition, hiddenFields, systemFields]
  );

  const { getViews } = useViews(entityName, columns, systemView);

  const {
    action,
    location: { pathname },
  } = useHistory();
  const key = ['list-props', unifyPathName(pathname), dialog].filter((v) => !!v).join('/');

  const [currentView, setCurrentView] = useState('');
  const [queryParams, setQueryParams] = useState<QueryParams>({
    page: 1,
    sorting: [],
    search: '',
    filters: [],
    links: {},
    ...initialValues,
  });

  const updateQueryParams = useCallback(
    (params: Partial<QueryParams>) => setQueryParams((v) => ({ ...v, ...params })),
    []
  );

  const defaultColumnsConfig = useMemo(
    () =>
      baseColumnsConfig ||
      columns.map((name) => ({
        name,
        visible: false,
        pinned: false,
        width: MIN_CELL_WIDTH,
      })),
    [baseColumnsConfig, columns]
  );

  const [columnsConfig, setColumnsConfig] = useState<ColumnConfig[]>(() =>
    defaultColumnsConfig.map((v) => ({ ...v, visible: true }))
  );

  const { defaultViews } = useContext(UserSettingsContext);
  const [isLoading, setIsLoading] = useState(true);
  const pinnedView = useMemo(() => defaultViews[entityName], [defaultViews, entityName]);
  const [views, setViews] = useState<Record<string, View>>({});

  const systemViewId = useMemo(
    () => Object.values(views).find((v) => v.isSystem && v.name === systemView)?.id,
    [systemView, views]
  );

  useEffect(() => {
    if (action !== 'POP') {
      localStorage.removeItem(key);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkFieldExists = useCallback(
    (name: string) => {
      const [shortName, realEntityName] = name.split('.').reverse();
      return (
        [
          ...(config[(realEntityName || entityName) as TEntityName].columns as readonly string[]),
          ...systemBasedFields,
        ].includes(shortName) && !hiddenFields.includes(name)
      );
    },
    [entityName, hiddenFields]
  );

  const clearOutdatedQueryParams = useCallback(
    (props: QueryParams) => ({
      search: '',
      ...props,
      sorting: (props.sorting || []).filter(({ name }) => checkFieldExists(name)),
      filters: (props.filters || []).filter(({ attribute: name }) => checkFieldExists(name)),
      links: Object.fromEntries(
        Object.entries(props.links || {})
          .map(([linkName, link]) => [
            linkName,
            {
              ...link,
              condition: ((link as EntityLink)?.condition || []).filter(({ attribute: name }) =>
                checkFieldExists(`${linkName}.${name}`)
              ),
            },
          ])
          .filter(([, link]) => ((link as EntityLink)?.condition?.length ?? 0) > 0)
      ),
    }),
    [checkFieldExists]
  );

  const clearOutdatedColumns = useCallback(
    (columnsConfig: ColumnConfig[]) =>
      (columnsConfig || [])
        .concat(...defaultColumnsConfig)
        .filter(({ name }, index, arr) => checkFieldExists(name) && arr.findIndex((v) => v.name === name) === index),
    [checkFieldExists, defaultColumnsConfig]
  );

  useLayoutEffect(() => {
    if (hasViews) {
      getViews()
        .then((views) => {
          setViews(views);
          const savedParams = localStorage.getItem(key);
          if (savedParams) {
            try {
              const {
                columnsConfig,
                currentView = '',
                ...rest
              } = JSON.parse(savedParams) as QueryParams & {
                columnsConfig: ColumnConfig[];
                currentView: string;
              };
              if (currentView) {
                setQueryParams(clearOutdatedQueryParams(rest));
                setColumnsConfig(clearOutdatedColumns(columnsConfig));
                setCurrentView(currentView);
              }
            } catch (e) {
              devLog('There Are no saved settings');
            }
          }
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading && (!currentView || !views[currentView]) && hasViews) {
      if (systemViewId && views[systemViewId]) {
        setCurrentView(systemViewId);
      } else if (pinnedView && views[pinnedView]) {
        setCurrentView(pinnedView);
      } else {
        const id = Object.values(views).find((v) => v.isSystem)?.id;
        if (id && views[id]) {
          setCurrentView(id);
        } else {
          console.error('There Are no View to Load');
        }
      }
    }
  }, [
    clearOutdatedColumns,
    clearOutdatedQueryParams,
    currentView,
    hasViews,
    isLoading,
    pinnedView,
    systemView,
    systemViewId,
    views,
  ]);

  useEffect(() => {
    if (currentView && views[currentView] && !isLoading) {
      const { filters, links, search, sorting, columnsConfig } = views[currentView];

      setQueryParams(clearOutdatedQueryParams({ filters, links: links, search, sorting, page: 1 }));
      setColumnsConfig(clearOutdatedColumns(columnsConfig));
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [currentView]);

  const baseViewParams = useMemo(() => {
    if (!currentView || !views[currentView]) return;
    const { filters, links, search, sorting, columnsConfig } = views[currentView];
    return {
      ...clearOutdatedQueryParams({ filters, links, search, sorting, page: 1 }),
      columnsConfig: clearOutdatedColumns(columnsConfig),
    };
  }, [clearOutdatedColumns, clearOutdatedQueryParams, currentView, views]);

  const hasChanges = useMemo(() => {
    return baseViewParams ? !deepEqual({ columnsConfig, ...queryParams }, baseViewParams, ['page']) : false;
  }, [baseViewParams, columnsConfig, queryParams]);

  useEffect(() => {
    if (!isLoading) {
      localStorage.setItem(key, JSON.stringify({ currentView, ...queryParams, columnsConfig }));
    }
  }, [columnsConfig, currentView, isLoading, key, queryParams]);

  const isCustomView = useMemo(() => !views[currentView]?.isSystem, [currentView, views]);

  return (
    <TableContext.Provider
      value={{
        setCurrentView,
        setColumnsConfig,
        updateQueryParams,
        queryParams,
        columnsConfig,
        hasChanges,
        currentView,
        columns,
        views,
        setViews,
        logicalName,
        isCustomView,
      }}
    >
      {!isLoading && ((currentView && views[currentView]) || !hasViews) ? children : null}
    </TableContext.Provider>
  );
};

export const useTableProps = () => useContext(TableContext);

export const connectListPropsProvider =
  (Component: (props: TListPage) => JSX.Element) =>
  ({
    dialog,
    columns: baseColumns,
    systemView,
    ...props
  }: TListPage & { dialog?: TEntityName; columns: readonly string[] }) => {
    const { hiddenFields } = useMetaData(props.entityName);
    const columns = baseColumns.filter(
      (name) => props.config?.[name]?.hiddenForTable !== true && !hiddenFields.includes(name)
    );
    return (
      <TableProvider entityName={props.entityName} dialog={dialog} columns={columns} systemView={systemView}>
        <Component {...props} />
      </TableProvider>
    );
  };
