import classes from './accountinfo.module.scss';
import { useCallback, useContext, useState, useEffect, useRef, useMemo } from 'react';
import { AuthContext } from 'providers/AuthProvider';
import { Modal } from 'components/Modal';
import { Trans, useTranslation } from 'react-i18next';
import { UserSettingsContext } from 'providers/UserSettingsProvider';
import { ReactComponent as TourLogo } from './icons/tour.svg';
import { ReactComponent as InfoIcon } from './icons/info.svg';
import { ReactComponent as EmailIcon } from './icons/email.svg';
import { ReactComponent as ZoneIcon } from './icons/zone.svg';
import { ReactComponent as SettingsIcon } from './icons/settings.svg';
import Tooltip from 'components/Tooltip';
import { ScreenContext } from 'providers/ScreenProvider';
import { debounce, devLog } from 'lib/helpers';
import { useApi } from 'domain/api';
import { useLoader } from 'providers/LoaderProvider';
import { useNotifications } from 'providers/NotificationsProvider';
import { TimeZoneSettings } from 'components/Header/components/TimeZoneSettings';
import { timeZoneToString, useTimeZone } from 'lib/hooks';

const AccountInfo = ({ onHide, visible }: { visible: boolean; onHide: () => void }) => {
  const { entityImage, fullName, internalEmailAddress, logout } = useContext(AuthContext);
  const { timeZone } = useTimeZone();

  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);

  const { isMobile, isTablet } = useContext(ScreenContext);
  const image = useMemo(() => (entityImage ? 'data:image/jpg;base64,' + entityImage : undefined), [entityImage]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        onHide();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef, onHide]);

  const confirmSignOut = useCallback(() => {
    onHide();
    setShowConfirmationMessage(true);
  }, [onHide]);

  const hideConfirmationMessage = useCallback(() => {
    setShowConfirmationMessage(false);
  }, []);

  const onLogOutClick = useMemo(
    () =>
      debounce(() => {
        setShowConfirmationMessage(false);
        logout();
      }, 100),
    [logout]
  );

  const { unitName } = useContext(UserSettingsContext);

  const { confirmEmail } = useApi();
  const { showLoader, hideLoader } = useLoader();
  const { addActionUncompleted } = useNotifications();

  const emailClick = useCallback(() => {
    showLoader();
    confirmEmail()
      .then(({ data }) => {
        window.open(data.GmailAuthenticationLink, '_black');
      })
      .catch((e) => {
        devLog(e);
        addActionUncompleted(t('There is no need to Authenticate your Email'));
      })
      .finally(() => hideLoader());
  }, [addActionUncompleted, confirmEmail, hideLoader, showLoader, t]);

  const [displayZones, setDisplayZones] = useState(false);

  const showTimeZones = useCallback(() => {
    setDisplayZones(true);
    onHide();
  }, [onHide]);

  const timeZoneString = useMemo(() => timeZoneToString(timeZone, true), [timeZone]);

  return (
    <>
      {visible && (
        <div ref={containerRef} className={classes.accountinfo}>
          <div className={classes.header}>
            <span>
              <Trans>Inquiry Management System</Trans>
            </span>
            <span className={classes.signout} onClick={confirmSignOut}>
              <Trans>Sign Out</Trans>
            </span>
          </div>
          <div className={classes.userWrapper}>
            <div className={classes.user}>
              <div className={classes.placeholder}>
                {image ? (
                  <img alt="image" src={image} />
                ) : (
                  <>
                    {fullName
                      ?.split(' ')
                      .map((v) => v.split('')[0])
                      .join('')}
                  </>
                )}
              </div>
            </div>
            <div className={classes.namebox}>
              <div className={classes.username}>{fullName}</div>
              <div className={classes.email}>{internalEmailAddress}</div>
              <div className={classes.email}>{unitName}</div>
            </div>
          </div>
          <button onClick={emailClick} className={classes.button}>
            <EmailIcon />
            <span>{t('Authenticate Email Address')}</span>
            <Tooltip
              content={
                <Trans>
                  <p>
                    Please let us know that you have completed this email authentication at{' '}
                    <span className={classes.email}>ims-help@bahai.us</span>
                  </p>
                </Trans>
              }
            >
              <div>
                <InfoIcon />
              </div>
            </Tooltip>
          </button>
          <button onClick={showTimeZones} className={classes.button}>
            <ZoneIcon />
            <span>{timeZoneString}</span>
            <SettingsIcon />
          </button>
        </div>
      )}
      {displayZones && <TimeZoneSettings onClose={() => setDisplayZones(false)} />}
      {showConfirmationMessage && (
        <Modal
          className={classes.signoutConfirmation}
          title={t('Sign Out')}
          header={t('Are you sure you want to Sign Out?')}
          onClose={hideConfirmationMessage}
          controls={[
            {
              title: t('Ok'),
              role: 'primary',
              onClick: onLogOutClick,
            },
            {
              title: t('Cancel'),
              onClick: hideConfirmationMessage,
            },
          ]}
        />
      )}
    </>
  );
};

export default AccountInfo;
