const env = process.env.REACT_APP_ENV || 'dev';

export const params = {
  dev: {
    url: 'https://dev-bahai.com:3000',
    scope: 'https://dev-bahai.api.crm.dynamics.com',
  },
  qa: {
    url: 'https://qa-tmc.bahai.us',
    scope: 'https://qa-bahai.api.crm.dynamics.com',
  },
  rc: {
    url: 'https://qa-rc-ims.bahai.us/',
    scope: 'https://qa-rc-bahai.crm.dynamics.com',
  },
  uat: {
    url: 'https://uat-ims.bahai.us',
    scope: 'https://uat-bahai.api.crm.dynamics.com',
  },
  trn: {
    url: 'https://white-bush-0d066b710.2.azurestaticapps.net',
    scope: 'https://training-bahai.api.crm.dynamics.com',
  },
  prod: {
    url: 'https://ims.bahai.us',
    scope: 'https://bahai.crm.dynamics.com',
  },
};

const { url, scope, reportId } = params[env];

export const API_URL = scope;
export const REPORT_ID = reportId;

export const msalConfig = {
  auth: {
    clientId: '7aa19b2d-ab69-4c17-ae6b-f71ce193eb64',
    authority: 'https://login.microsoftonline.com/a88ed93c-f89e-455c-b935-6e2eff84d052',
    redirectUri: `${url}`,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginRequest = {
  scopes: [`${scope}/user_impersonation`],
};
