import { createContext, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useApi } from 'domain/api';
import { TEntityName } from 'lib';

type Settings = {
  id?: string;
  defaultViews: Record<TEntityName, string>;
  pageSize: number;
};

export const UserSettingsContext = createContext(
  {} as {
    defaultViews: Record<TEntityName, string | undefined>;
    pageSize: number;
    setPageSize: (records: number) => void;
    pinView: (entityName: TEntityName, id: string) => void;
    unitName?: string;
  }
);

export const UserSettingsProvider: FC = ({ children }) => {
  const { getUserSettings, updateUserSettings, addUserSettings, getBusinessUnitName } = useApi();
  const [loading, setLoading] = useState(true);

  const idRef = useRef('');

  const [settings, setSettings] = useState({
    defaultViews: {},
    pageSize: 100,
  } as Settings);

  const { defaultViews, pageSize } = useMemo(() => settings, [settings]);

  const [unitName, setUnitName] = useState('');

  useEffect(() => {
    (async () => {
      setUnitName(await getBusinessUnitName());
    })();
  }, [getBusinessUnitName]);

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { value },
        } = await getUserSettings();
        const { bahai_usersettingsid: id, bahai_body } = value.reverse()[0];
        idRef.current = id;
        if (bahai_body) {
          setSettings((v) => ({ ...v, ...JSON.parse(bahai_body), unitName }));
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error('Can`t get user settings');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading) {
      if (idRef.current) {
        updateUserSettings(idRef.current, JSON.stringify(settings));
      } else {
        addUserSettings(JSON.stringify(settings))
          .then((resp) => {
            idRef.current = resp?.headers?.location?.match(/\((.+)\)/)?.[1] as string;
          })
          .catch((e) => {
            console.error(e);
          });
      }
    }
  }, [addUserSettings, loading, settings, updateUserSettings]);

  const pinView = useCallback((entityName: TEntityName, viewId: string) => {
    /*setSettings((v) => ({
        ...v,
        defaultViews: {
          ...v.defaultViews,
          [entityName]: String(defaultViews[entityName]) === String(viewId) ? undefined : String(viewId),
        },
      }));*/
  }, []);

  const setPageSize = useCallback((pageSize: number) => {
    setSettings((v) => ({ ...v, pageSize }));
  }, []);

  return (
    <UserSettingsContext.Provider value={{ defaultViews, pinView, setPageSize, pageSize, unitName }}>
      {loading ? null : children}
    </UserSettingsContext.Provider>
  );
};
