import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useMsal,
  useMsalAuthentication,
} from '@azure/msal-react';
import { loginRequest, msalConfig } from 'domain/authConfig';
import { AccountInfo, InteractionType, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import { Placeholder } from './components/Placeholder';
import axios from 'axios';
import { baseURL } from 'domain/api';
import baseConfig from 'config/index';
import { Privilege, TEntityName } from 'lib';
import { ErrorPage } from 'components/ErrorBoundary/ErrorPage';
import { Trans } from 'react-i18next';
import { ReactComponent as ErrorIcon } from 'components/ErrorBoundary/icons/Error404.svg';
import { useAgreementInfo } from 'components/AgreementInfo/hooks';

import * as Sentry from '@sentry/react';
import { useSyncStorage } from 'lib/hooks';
import PopupBlockedPlaceholder from 'components/PupupBlockedPlaceholder';
import { debounce, devLog } from 'lib/helpers';
import { UserRoles } from 'config/EntityMetadata/systemuser';

type TAuthContext = {
  user: AccountInfo;
  getToken: () => Promise<string>;
  logout: () => void;
  privileges: Record<TEntityName, Privilege[]>;
  roles: string[];
  teams: string[];
  systemuserid: string;
  internalEmailAddress: string;
  fullName: string;
  entityImage: string;
  businessUnitType: string;
} & TUserInfo;

type TUserInfo = {
  systemuserid: string;
  internalEmailAddress: string;
  fullName: string;
  entityImage: string;
  teams: string[];
  roles: string[];
  businessUnitType: string;
  securityRoles: UserRoles[];
};

const entityNames = Object.keys(baseConfig) as TEntityName[];

const getLogicalName = (name: TEntityName) => {
  switch (name) {
    /*case 'interaction':
    case 'email':
    case 'reminder':
      return 'Activity';
    case 'member':
      return 'Contact';*/
    default:
      return baseConfig[name].name;
  }
};

export const AuthContext = createContext({} as TAuthContext);

export const AuthProvider: FC = ({ children }) => {
  const { login, error, result, acquireToken } = useMsalAuthentication(InteractionType.Redirect, {
    ...loginRequest,
    prompt: 'select_account',
  } as RedirectRequest);

  const [popupsBlocked, setPopupsBlocked] = useState(false);

  useEffect(() => {
    if (error && error.errorCode === 'popup_window_error') {
      setPopupsBlocked(true);
    }
  }, [error]);

  const { instance, inProgress } = useMsal();
  const account = useAccount();
  const [userInfo, setUserInfo] = useSyncStorage<TUserInfo>('userInfo');
  const [loading, setLoading] = useState<boolean>(true);
  const [showError, setShowError] = useState(false);
  const [removeDocumentsAllowed, setRemoveDocumentsAllowed] = useState(false);
  const { checkAgreements, content: agreementsContent } = useAgreementInfo();
  const [privileges, setPrivileges] = useState({
    ...Object.fromEntries(entityNames.map((name) => [name, [] as Privilege[]])),
    /*resource: [Privilege.Read],*/
    /*document: [Privilege.Read],*/
  } as Record<TEntityName, Privilege[]>);
  const finalPrivileges = useMemo(
    () => ({
      ...privileges,
      document: removeDocumentsAllowed ? [Privilege.Read, Privilege.Delete] : [Privilege.Read],
    }),
    [privileges, removeDocumentsAllowed]
  );

  const loginTry = useCallback(() => {
    login(InteractionType.Redirect, loginRequest)
      .then(() => setPopupsBlocked(false))
      .catch((e) => {
        if (e.errorCode === 'popup_window_error') {
          setPopupsBlocked(true);
        } else {
          if (e.errorCode !== 'interaction_in_progress') {
            console.error(e.errorCode);
            location.reload();
          }
        }
      });
  }, [login]);

  useEffect(() => {
    if (!error && !result && !account && inProgress === 'none') {
      loginTry();
    }
  }, [account, error, inProgress, loginTry, result]);

  const logout = useMemo(
    () =>
      debounce(() => {
        setUserInfo();
        instance
          .logoutRedirect({
            authority: msalConfig.auth.authority,
            account: account,
            postLogoutRedirectUri: msalConfig.auth.redirectUri,
          })
          .then(() => devLog('Logout success or Popup Closed'))
          .catch((e) => {
            devLog('Logout Failure', e);
          });
      }, 300),
    [account, instance, setUserInfo]
  );

  const getToken = useCallback(async () => {
    try {
      const result = await instance.acquireTokenSilent({
        ...loginRequest,
        account: instance.getActiveAccount() ?? undefined,
      } as SilentRequest);
      devLog('Base silent refresh success');
      return result?.accessToken;
    } catch (e) {
      try {
        const resp = await acquireToken(InteractionType.Redirect, loginRequest);
        devLog('Instance redirect refresh success');
        return resp?.accessToken || '';
      } catch (e) {
        logout();
      }
    }
    return '';
  }, [acquireToken, instance, logout]);

  const checkAccess = useCallback(async (token: string) => {
    devLog('Check Access');
    await axios.request({
      url: baseURL + `EntityDefinitions(LogicalName='systemuser')`,
      headers: { Authorization: 'Bearer ' + token },
      params: {
        $select: 'DisplayName',
      },
    });
  }, []);

  const getUserInfo = useCallback(async (localAccountId: string, token: string) => {
    const {
      data: {
        value: [
          {
            systemuserid,
            internalemailaddress,
            fullname,
            entityimage = '',
            teammembership_association,
            systemuserroles_association,
            businessunitid,
            bahai_securityrole = '',
          },
        ],
      },
    } = await axios.request<{
      value: [
        {
          systemuserid: string;
          internalemailaddress: string;
          fullname: string;
          entityimage: string;
          bahai_securityrole: string;
          teammembership_association: { name: string }[];
          systemuserroles_association: { name: string }[];
          businessunitid: { bahai_type: string };
        }
      ];
    }>({
      url: baseURL + 'systemusers',
      params: {
        $select: 'systemuserid,internalemailaddress,fullname,entityimage,bahai_securityrole',
        $filter: `azureactivedirectoryobjectid eq '${localAccountId}'`,
        $expand:
          'teammembership_association($select=name),systemuserroles_association($select=name),businessunitid($select=bahai_type)',
      },
      headers: { Authorization: 'Bearer ' + token },
    });

    return {
      systemuserid,
      internalemailaddress,
      fullname,
      entityimage,
      teammembership_association,
      systemuserroles_association,
      businessunitid,
      securityRoles: bahai_securityrole ? bahai_securityrole.split(',').map((v) => parseInt(v)) : [],
    };
  }, []);

  const getDocumentsAccess = useCallback(async (token: string) => {
    const { data } = await axios.request<{ value: Array<{ defaultvalue: string }> }>({
      url: baseURL + 'environmentvariabledefinitions',
      headers: { Authorization: 'Bearer ' + token },
      params: {
        $select: 'displayname,defaultvalue',
        $expand: 'environmentvariabledefinition_environmentvariablevalue($select=value)',
        $filter: "schemaname eq 'bahai_sharepointdocumentcustomribbonenablerulesconfiguration'",
      },
    });
    const rules = JSON.parse(data.value[0].defaultvalue) as {
      EnableRules: Array<{ SecurityRolesName: string[]; CommandId: string }>;
    };
    return rules.EnableRules[0].SecurityRolesName || [];
  }, []);

  const getACL = useCallback(
    async (userInfo: TUserInfo, token: string) => {
      const roles = await getDocumentsAccess(token);
      setRemoveDocumentsAllowed(userInfo.roles.some((userRole) => roles.includes(userRole)));

      const {
        data: { RolePrivileges },
      } = await axios.get<{ RolePrivileges: Array<{ PrivilegeName: string }> }>(
        baseURL + `systemusers(${userInfo.systemuserid})/Microsoft.Dynamics.CRM.RetrieveUserPrivileges()`,
        {
          headers: { Authorization: 'Bearer ' + token },
        }
      );
      return RolePrivileges;
    },
    [getDocumentsAccess]
  );

  const initiateUserInfo = useCallback(
    async (account: AccountInfo) => {
      const accessToken = await getToken();
      try {
        await checkAccess(accessToken);
        const userInfoResponse = await getUserInfo(account.localAccountId, accessToken);
        const userInfo = {
          fullName: userInfoResponse.fullname,
          entityImage: userInfoResponse.entityimage,
          systemuserid: userInfoResponse.systemuserid,
          internalEmailAddress: userInfoResponse.internalemailaddress,
          roles: userInfoResponse.systemuserroles_association.map((role) => role.name),
          teams: userInfoResponse.teammembership_association.map((team) => team.name),
          businessUnitType: userInfoResponse.businessunitid.bahai_type,
          securityRoles: userInfoResponse.securityRoles,
        };
        const acl = await getACL(userInfo, accessToken);

        setUserInfo(userInfo);
        setPrivileges((privileges) =>
          acl.reduce((acc, prvObj) => {
            const names = entityNames.filter((name) =>
              prvObj.PrivilegeName.endsWith(getLogicalName(name as TEntityName))
            );
            if (!names.length) return acc;
            const privilege = prvObj.PrivilegeName.substr(3).replace(getLogicalName(names[0]), '') as Privilege;
            return {
              ...acc,
              ...Object.fromEntries(names.map((name) => [name, [...acc[name], privilege]])),
            };
          }, privileges)
        );
        checkAgreements(userInfo.systemuserid, accessToken).then();
      } catch (error: any) {
        devLog(error);
        setShowError(true);
      } finally {
        setLoading(false);
      }
    },
    [checkAccess, checkAgreements, getACL, getToken, getUserInfo, setUserInfo]
  );

  useEffect(() => {
    if (account?.localAccountId) {
      if (account.idTokenClaims?.exp) {
        devLog(new Date(account.idTokenClaims?.exp).toLocaleString());
      }
      initiateUserInfo(account).then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account?.localAccountId]);

  useEffect(() => {
    if (showError) setShowError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  if (process.env.NODE_ENV === 'production') {
    //Sentry.setUser(account);
  }

  if (popupsBlocked) return <PopupBlockedPlaceholder />;

  if (!loading && showError) {
    return (
      <ErrorPage
        title={<Trans>You don’t have access to this application</Trans>}
        description={
          <Trans>
            Current User {account?.username} doesn’t have access to this application. Please try to login as another
            User or contact your regional seeker response coordinator or Inquiry Services liaison. Please reload the
            page after the access will be granted by administrator.
          </Trans>
        }
        CustomIcon={ErrorIcon}
        showLogout={true}
        showRefresh={true}
        showGoHome={false}
      />
    );
  }

  return (
    <>
      {!loading ? (
        <AuthContext.Provider
          value={{
            getToken,
            logout,
            user: account ?? ({} as AccountInfo),
            privileges: finalPrivileges,
            ...(userInfo as TUserInfo),
          }}
        >
          <AuthenticatedTemplate>{agreementsContent ?? children}</AuthenticatedTemplate>
          <UnauthenticatedTemplate />
        </AuthContext.Provider>
      ) : (
        <Placeholder />
      )}
    </>
  );
};

export const useSecurity = () => {
  const { privileges } = useContext(AuthContext);

  const isGranted = useCallback(
    (entity: TEntityName, privilege: Privilege) => privileges[entity].includes(privilege),
    [privileges]
  );

  return { isGranted };
};
