import { TValueComponent } from 'lib';
import { useCallback, useEffect, useMemo } from 'react';
import classes from 'components/Table/Filter/filter.module.scss';

export const FromToComponent = (Component: TValueComponent): TValueComponent => {
  const WrappedComponent: TValueComponent = ({ value, onChange }) => {
    const [from, to] = useMemo(() => (value || ',').split(','), [value]);

    useEffect(() => {
      if (from === '' && to === '' && value !== '') onChange('');
    }, [from, onChange, to, value]);

    const changeFrom = useCallback((from) => onChange([from, to].join(',')), [onChange, to]);
    const changeTo = useCallback((to) => onChange([from, to].join(',')), [onChange, from]);

    return (
      <div className={classes.between}>
        <Component value={from} onChange={changeFrom} />
        <Component value={to} onChange={changeTo} />
      </div>
    );
  };
  return WrappedComponent;
};
