export const systemBasedFields = ['ownerid', 'createdon', 'createdby', 'modifiedon'] as const;
export type SystemFields = typeof systemBasedFields[number];
export type Fields<T extends string> = SystemFields | T;

export default {
  entity: {
    name: 'entity',
    url: 'entity',
    columns: ['field'],
    fields: ['field1'],
  },
} as const;
