import { createContext, FC } from 'react';
import { TAppEntityMeta, TJoinParams } from 'dynamics-meta';
import meta from 'domain/meta.json';
import { TEntityName } from 'lib';

type TMetaDataContext = {
  config: Record<string, TAppEntityMeta>;
  joinParams: Record<string, TJoinParams>;
};

export const MetaDataContext = createContext({} as TMetaDataContext);

export const MetaDataProvider: FC = ({ children }) => {
  return (
    <MetaDataContext.Provider
      value={{
        config: meta.meta as unknown as Record<TEntityName, TAppEntityMeta>,
        joinParams: meta.targetCollections as unknown as Record<string, TJoinParams>,
      }}
    >
      {children}
    </MetaDataContext.Provider>
  );
};
