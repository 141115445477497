import ErrorBoundary from 'components/ErrorBoundary';
import Header from 'components/Header';
import { ErrorProvider } from 'providers/ErrorProvider';

const App = () => {
  return (
    <>
      <ErrorProvider>
        <ErrorBoundary background={'white'}>
          {(showMenu: boolean, error: JSX.Element | undefined, showHeader) => (
            <>
              {showHeader && <Header />}
              <div>Content</div>
            </>
          )}
        </ErrorBoundary>
      </ErrorProvider>
    </>
  );
};

export { App };
