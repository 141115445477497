import { useCallback, useState, useMemo, useRef } from 'react';
export const baseURL = API_URL + '/api/data/v9.2/';
import { API_URL } from 'domain/authConfig';
import { useServerError } from 'providers/ErrorProvider';
import { AgreementContent } from 'components/AgreementInfo/index';
import { createAxiosInstance, formatXmlQuery } from 'domain/api/setup';

export type AgreementResponse = {
  'agreement.bahai_title': string;
  'agreement.bahai_body': string;
  bahai_signatureid: string;
};

export const useAgreementInfo = () => {
  const [agreements, setAgreements] = useState<AgreementResponse[]>([]);
  const [loading, setLoading] = useState(true);
  const addServerError = useServerError();

  const axios = useMemo(() => createAxiosInstance(addServerError), [addServerError]);

  const getAgreement = useCallback(
    (systemuserid, token) => {
      const query = {
        fetch: {
          _attributes: {
            version: '1.0',
            'output-format': 'xml-platform',
            returntotalrecordcount: true,
            'no-lock': true,
            distinct: true,
          },
          entity: {
            _attributes: {
              name: 'bahai_signature',
            },
            attribute: [
              {
                _attributes: { name: 'bahai_expirationdate' },
              },
              {
                _attributes: { name: 'bahai_signatureid' },
              },
            ],
            filter: [
              {
                _attributes: { type: 'and' },

                condition: [
                  {
                    _attributes: { attribute: 'ownerid', operator: 'eq', value: systemuserid },
                  },
                ],
                filter: [
                  {
                    _attributes: { type: 'or' },
                    condition: [
                      {
                        _attributes: { attribute: 'bahai_expirationdate', operator: 'null' },
                      },
                      {
                        _attributes: {
                          attribute: 'bahai_expirationdate',
                          operator: 'lt',
                          value: new Date().toISOString(),
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            'link-entity': [
              {
                _attributes: {
                  name: 'bahai_agreement',
                  from: 'bahai_agreementid',
                  to: 'bahai_agreementid',
                  alias: 'agreement',
                },
                attribute: [
                  {
                    _attributes: { name: 'bahai_title' },
                  },
                  {
                    _attributes: { name: 'bahai_body' },
                  },
                ],
              },
            ],
          },
        },
      };
      return axios.request<{ value: AgreementResponse[] }>({
        url: 'bahai_signatures',
        headers: { Authorization: 'Bearer ' + token },
        params: {
          fetchXml: formatXmlQuery(query),
        },
      });
    },
    [axios]
  );

  const signAgreement = useCallback(
    (id, token) =>
      axios.request({
        url: `bahai_signatures(${id})/Microsoft.Dynamics.CRM.bahai_signagreement`,
        method: 'post',
        headers: { Authorization: 'Bearer ' + token },
      }),
    [axios]
  );

  const tokenRef = useRef<string>('');

  const lastAgreement = useMemo(() => agreements[0], [agreements]);

  const agree = useCallback(() => {
    setLoading(true);
    signAgreement(lastAgreement?.bahai_signatureid, tokenRef.current)
      .then(() => setAgreements((v) => v.filter((_, index) => index !== 0)))
      .finally(() => setLoading(false));
  }, [lastAgreement, signAgreement]);

  const checkAgreements = useCallback(
    async (systemuserid: string, token: string) => {
      tokenRef.current = token;
      try {
        const {
          data: { value: agreements },
        } = await getAgreement(systemuserid, token);
        setAgreements(agreements);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [getAgreement]
  );

  const content = useMemo(
    () =>
      lastAgreement && !loading ? <AgreementContent onAgree={agree} {...lastAgreement} /> : loading ? undefined : null,
    [agree, lastAgreement, loading]
  );

  return { checkAgreements, content };
};
